import { gql } from "@apollo/client";

export const ReviewIOReviewsQuery = gql`
  query Reviews {
    getReviewIOReviews {
      reviews {
        store_review_id
        comments
        date
        rating
        reviewer {
          first_name
          last_name
        }
      }
      stats {
        total_reviews
        average_rating
      }
      word
    }
  }
`;
