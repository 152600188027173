"use client";

import { useOffer } from "./OfferContext";
import { Replacement } from "./extractDiscountAndOffer";

export function mergeOfferText(
  text: string,
  basePrice: string,
  offerPrice: string,
  replacements: Replacement[],
) {
  let result = text;

  result = result.replaceAll(basePrice, offerPrice);

  replacements.forEach(({ from, to }) => {
    result = result.replaceAll(from, to);
  });

  return result;
}

export function OfferAwareText({ text }: { text: string }) {
  const { basePrice, offerPrice, replacements } = useOffer();

  return <>{mergeOfferText(text, basePrice, offerPrice, replacements)}</>;
}
