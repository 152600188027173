"use client";

import {
  pseudoHeadingGuideTypography,
  styleGuideTypography,
  styleGuideTypographySmallMobile,
} from "@/utils/style-guide-typography";
import { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import { mergeOfferText } from "./OfferAwareText";
import { useOffer } from "./OfferContext";

export function OfferAwareRichText({
  field,
  useSmallMobileText = false,
  usePseudoHeadings = false,
}: {
  field: RichTextField | null | undefined;
  useSmallMobileText?: boolean;
  usePseudoHeadings?: boolean;
}) {
  const { basePrice, offerPrice, replacements } = useOffer();

  if (field) {
    field.forEach((node) => {
      if (
        node.type === "heading1" ||
        node.type === "heading2" ||
        node.type === "heading3" ||
        node.type === "heading4" ||
        node.type === "heading5" ||
        node.type === "heading6" ||
        node.type === "paragraph" ||
        node.type === "list-item" ||
        node.type === "o-list-item"
      ) {
        node.text = mergeOfferText(
          node.text,
          basePrice,
          offerPrice,
          replacements,
        );
      }
    });
  }

  return (
    <PrismicRichText
      field={field}
      components={{
        ...styleGuideTypography,
        ...(usePseudoHeadings ? pseudoHeadingGuideTypography : {}),
        ...(useSmallMobileText ? styleGuideTypographySmallMobile : {}),
        em: ({ children }) => (
          <span className="text-cgc-yellow">{children}</span>
        ),
      }}
    />
  );
}
