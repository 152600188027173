import {
  LandingPageDocumentDataPathDiscountMapItem,
  LandingPageDocumentDataUtmSourceDiscountMapItem,
} from "@/prismicio-types";
import { Content } from "@prismicio/client";
import { ReadonlyURLSearchParams } from "next/navigation";

export type Replacement = {
  from: string;
  to: string;
};

export type DiscountSet = {
  discount: string;
  offerPrice: string;
  replacements: Replacement[];
  url?: string;
};

function extractReplacements(
  source:
    | LandingPageDocumentDataPathDiscountMapItem
    | LandingPageDocumentDataUtmSourceDiscountMapItem,
): Replacement[] {
  const replacements: Replacement[] = [];

  if (source.replacements) {
    const splitSource = source.replacements.split("\n");

    splitSource.forEach((replacement) => {
      const [from, to] = replacement.split("=>");

      replacements.push({
        from,
        to,
      });
    });
  }

  return replacements;
}

export function extractDiscountAndOffer(
  page: Content.LandingPageDocument,
  pathName: string,
  query?: ReadonlyURLSearchParams,
): DiscountSet[] {
  const discounts: DiscountSet[] = [
    {
      discount: page.data.base_discount_code,
      offerPrice: page.data.base_offer_string,
      replacements: [],
    },
  ];

  if (page.data.additional_offer_sets) {
    page.data.additional_offer_sets.forEach((source) => {
      discounts.push({
        discount: source.base_discount_code,
        offerPrice: source.base_offer_string,
        url: source.sign_up_url,
        replacements: [],
      });
    });
  }

  if (page.data.path_discount_map) {
    const pathDiscounts = page.data.path_discount_map.filter(
      ({ path }) => path === pathName,
    );

    pathDiscounts.forEach((source) => {
      discounts.push({
        discount: source.discount_code,
        offerPrice: source.pricing,
        url: source.url,
        replacements: extractReplacements(source),
      });
    });
  }

  // Determine if the current query has a utm_source discount code
  if (query) {
    if (query.has("utm_source")) {
      const utmSourceDiscounts = page.data.utm_source_discount_map.filter(
        ({ utm_source }) => utm_source === query.get("utm_source"),
      );

      utmSourceDiscounts.forEach((source) => {
        discounts.push({
          discount: source.discount_code,
          offerPrice: source.pricing,
          url: source.url,
          replacements: extractReplacements(source),
        });
      });
    }

    // Determine if the current query has a discount code
    if (query.has("discount")) {
      const discountCode = query.get("discount");

      discounts.push({
        discount: discountCode,
        offerPrice: page.data.base_offer_string,
        replacements: [],
      });
    }
  }

  return discounts.reverse();
}
