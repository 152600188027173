"use client";

import { ReviewIOReviewsQuery } from "@/data/helpers/reviewIO/queries";
import { useQuery } from "@apollo/client/react/hooks";
import { Rating } from "@cgc/ui";
import Image from "next/image";

// apps/web/components/MemberReviews/MemberReviews.tsx
import reviewsLogo from "../../MemberReviews/reviewsio-logo.svg";

function Skeleton() {
  return (
    <a
      className="cursor-pointer no-underline"
      href="https://www.reviews.co.uk/company-reviews/store/craft-clubs"
    >
      <div className="flex flex-col items-center gap-4 lg:flex-row">
        <Image
          src={reviewsLogo}
          alt={"Reviews.io Logo"}
          width={200}
          height={50}
          className="h-6"
        />

        <div className="space-y-2">
          <p>
            Read our{" "}
            <span className="inline-block w-[60px] animate-pulse rounded-full bg-gray-300">
              &nbsp;
            </span>{" "}
            reviews
          </p>
          <Rating>
            <Rating.Star />
            <Rating.Star />
            <Rating.Star />
            <Rating.Star />
            <Rating.Star half />
          </Rating>
        </div>
      </div>
    </a>
  );
}

export function MiniReviews() {
  const { data } = useQuery(ReviewIOReviewsQuery);
  const { stats } = data?.getReviewIOReviews ?? {};

  return (
    <div>
      {!data && <Skeleton />}
      {data && (
        <div className="flex flex-col items-center gap-4 lg:flex-row">
          <Image
            src={reviewsLogo}
            alt={"Reviews.io Logo"}
            width={200}
            height={50}
          />

          <div className="space-y-2">
            <p>Read our {stats.total_reviews} reviews</p>
            <Rating>
              <Rating.Star />
              <Rating.Star />
              <Rating.Star />
              <Rating.Star />
              <Rating.Star half />
            </Rating>
          </div>
        </div>
      )}
    </div>
  );
}
